import { Upload, UploadDownloadableFiles, UploadS3Pathways } from '../../types'

export type setShowCopiedMessage = (showCopiedMessage: boolean) => void

export const copyToClipboard = (textToCopy: string, setShowCopiedMessage?: setShowCopiedMessage): void => {
	navigator.clipboard.writeText(textToCopy)

	if (setShowCopiedMessage) {
		setShowCopiedMessage(true)
		const hideCopiedMessage = setTimeout(() => {
			setShowCopiedMessage(false)
			clearTimeout(hideCopiedMessage)
		}, 2300)
	}
}

export const getS3Pathways = (uploadConfig: Upload['uploadConfig']): UploadS3Pathways => {
	const paths: Record<string, string> = {}
	if (uploadConfig) {
		const { downloadableFiles = [], uploadMatchResultPrefix = '' } = uploadConfig
		if (downloadableFiles.includes(UploadDownloadableFiles.CONSOLIDATED)) {
			paths.consolidated = uploadMatchResultPrefix + 'full_output.csv'
		}
		if (downloadableFiles.includes(UploadDownloadableFiles.MATCHED)) {
			paths.matched = uploadMatchResultPrefix + 'accepted.csv'
		}
		if (downloadableFiles.includes(UploadDownloadableFiles.UNMATCHED)) {
			paths.unmatched = uploadMatchResultPrefix + 'rejected.csv'
		}
	}

	return paths
}
