import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Dropdown } from '../../local-core-ui'

import cx from 'classnames'
import { orderBy } from 'lodash-es'
import { useModules } from '../../hooks/useEntitlements'
import { useFinalizeRecordsForUpload } from '../../queries/useFinalizeRecordsForUpload'
import { useSource } from '../../queries/useSource'
import { Upload, UploadS3Pathways } from '../../types'
import { AlphabeticalFilter } from '../alphabetical-filter/alphabetical-filter'
import { getS3Pathways } from '../upload-status-details/upload-status-utilities'
import { extractStatsFromUpload } from '../upload-tile/extractStatsFromUpload'
import { RelationUploads, UploadTile, UploadTileProps } from '../upload-tile/upload-tile'
import styles from './filters-uploads-modal.module.scss'

interface FiltersUploadsModalProps {
	totalUploads: number
	sourceId: string
	sourceName: string
	uploadsList: Array<RelationUploads>
	onClickRefresh?(upload: Upload, originalUpload?: Upload): void
	onClose: () => void
	selectedUpload: (upload: Upload) => void
	s3Pathway: UploadS3Pathways
	showMenuTiles?: boolean
	sourceEntityType?: UploadTileProps['sourceEntityType']
	sourceIntegrationType?: UploadTileProps['sourceIntegrationType']
	testId: string
}

interface UploadFilter {
	sortBy:
		| 'uploadFileName'
		| 'recentlyAdded'
		| 'uploadedBy'
		| 'submittedHiToLo'
		| 'submittedLoToHi'
		| 'matchedHiToLo'
		| 'matchedLoToHi'
	sortAscending: boolean
}

const filterDefaults: UploadFilter = {
	sortBy: 'recentlyAdded',
	sortAscending: true
}

export const FiltersUploadsModal: FC<FiltersUploadsModalProps> = ({
	totalUploads,
	sourceId,
	sourceName,
	uploadsList,
	onClickRefresh,
	onClose,
	selectedUpload,
	s3Pathway,
	showMenuTiles = true,
	sourceEntityType,
	sourceIntegrationType,
	testId
}: FiltersUploadsModalProps) => {
	const { t } = useTranslation()
	const [selectedFilterBy, setSelectedFilterBy] = useState<UploadFilter>(filterDefaults)
	const [sortUploadsList, setSortUploadsList] = useState<Array<RelationUploads>>(uploadsList)
	const [uploadsPerPage, setUploadsPerPage] = useState(20)
	const [currentPage, setCurrentPage] = useState(0)
	const [currentLetter, setCurrentLetter] = useState('')
	const [windowWidth, setWindowWidth] = useState(window.innerHeight)
	const stewardshipEnabled = useModules(['Stewardship'])
	const finalizeUploadMutation = useFinalizeRecordsForUpload()
	const sourceQuery = useSource(sourceId, true)

	const WIDTH_BREAKPOINT = 1400

	const handleSortUpload = (sort: UploadFilter['sortBy']) => {
		let updateUploadSort = selectedFilterBy
		const isAscending = sort !== 'submittedHiToLo' && sort !== 'matchedHiToLo' && sort !== 'recentlyAdded'
		updateUploadSort = { ...updateUploadSort, sortBy: sort, sortAscending: isAscending }
		setSelectedFilterBy(updateUploadSort)
		selectOrderType(sort, updateUploadSort)
	}

	const selectOrderType = (sort: UploadFilter['sortBy'], updatedUploadFilter: UploadFilter) => {
		orderList(sort, updatedUploadFilter, sortUploadsList)
	}

	const getRefUpload = (upload: RelationUploads) => {
		return upload.versions !== undefined && upload.versions.length > 0 ? upload.versions[0] : upload.original
	}

	const defineSortFunction = (sortBy: string) => {
		switch (sortBy) {
			case 'uploadFileName':
				return (upload: RelationUploads) => getRefUpload(upload).displayName.toLowerCase()
			case 'recentlyAdded':
				return (upload: RelationUploads) => getRefUpload(upload).uploadCreatedTime
			case 'uploadedBy':
				return (upload: RelationUploads) => getRefUpload(upload).createdBy.toLowerCase()
			case 'submittedHiToLo':
			case 'submittedLoToHi':
				return (upload: RelationUploads) => {
					const refUpload: Upload = getRefUpload(upload)
					return refUpload.uploadStats ? refUpload.uploadStats?.import.submitted : 0
				}
			case 'matchedHiToLo':
			case 'matchedLoToHi':
				return (upload: RelationUploads) => {
					const refUpload: Upload = getRefUpload(upload)
					return refUpload.uploadStats ? refUpload.uploadStats.match?.matched : 0
				}
		}
	}

	const orderList = (
		_sort: UploadFilter['sortBy'],
		updatedUploadFilter: UploadFilter,
		list: Array<RelationUploads>
	) => {
		const order = orderBy(
			list,
			defineSortFunction(updatedUploadFilter.sortBy),
			updatedUploadFilter.sortAscending ? ['asc'] : ['desc']
		)
		setSortUploadsList(order)
	}

	const sortUploadPage = () => {
		const uploadPages: Array<Array<RelationUploads>> = sortUploadsList.reduce((acc, curr, index) => {
			const pageIndex = Math.floor(index / uploadsPerPage)
			if (!acc[pageIndex]) {
				acc[pageIndex] = []
			}
			acc[pageIndex].push(curr)
			return acc
		}, [] as Array<Array<RelationUploads>>)
		return uploadPages.length && currentPage < uploadPages.length ? uploadPages[currentPage] : []
	}

	const getContainer = () => {
		const uploadPages = sortUploadPage()
		const marginTop =
			selectedFilterBy.sortBy === 'uploadedBy' || selectedFilterBy.sortBy === 'uploadFileName'
				? ''
				: styles.marginLarge
		if (uploadPages.length) {
			return (
				<div
					className={cx(styles.resultsContainer, marginTop, {
						[styles.reducedGrid]: windowWidth < WIDTH_BREAKPOINT
					})}
				>
					{uploadPages.map((upload: RelationUploads, index: number) => {
						const uploadCurrent: Upload = upload.selectedUploadVersion
						const { isApiAggregation, uploadConfig, uploadCreatedTime, uploadDiagnostics, uploadId } =
							uploadCurrent

						return (
							<div className={styles.containerUploadTile} key={'upload-' + index}>
								<UploadTile
									upload={uploadCurrent}
									errorMessageUpload={
										uploadDiagnostics.lastErrorMessage
											? JSON.parse(uploadDiagnostics.lastErrorMessage).errorMsg
											: ''
									}
									stats={extractStatsFromUpload(uploadCurrent, t)}
									displayedSection
									onClickSelection={() => {
										selectedUpload(uploadCurrent)
										onClose()
									}}
									onClickUpdateNow={() => finalizeUploadMutation.mutate(uploadId)}
									s3Pathway={getS3Pathways(uploadConfig)}
									downloadableFiles={uploadConfig?.downloadableFiles}
									testId={testId + '-upload-tile'}
									createdDate={uploadCreatedTime}
									showContextMenu={showMenuTiles}
									isApiBased={isApiAggregation}
									showUpdateNowButton={stewardshipEnabled}
									sourceEntityType={sourceEntityType}
									sourceIntegrationType={sourceIntegrationType}
									stewardshipDisabled={
										!(uploadCurrent.readyToSteward && sourceQuery.data?.stewardable)
									}
									uploadApplicationId={uploadDiagnostics.applicationId}
									expirationDate={uploadCurrent.expires}
									onClickRefresh={onClickRefresh}
									relationVersions={upload}
									onClickVersion={(onVersion: Upload) => {
										const idxChangeVersion = sortUploadsList.findIndex(
											(tileRelated) =>
												tileRelated.original.uploadId === onVersion.uploadId ||
												tileRelated.versions?.filter((version) => {
													return version.uploadId === onVersion.uploadId
												}).length
										)
										const copyTiles: Array<RelationUploads> = JSON.parse(
											JSON.stringify(uploadPages)
										)
										copyTiles[idxChangeVersion].selectedUploadVersion = onVersion
										setSortUploadsList([...copyTiles])
									}}
								/>
							</div>
						)
					})}
				</div>
			)
		} else {
			return (
				<div className={`${styles.resultsContainer} ${styles.containerEmpty} ${marginTop}`}>
					<div>{t('text.no.uploads')}</div>
				</div>
			)
		}
	}

	const filterByLetter = (letter: string) => {
		setCurrentLetter(letter)
		const newList = sortUploadsList.filter((relation: RelationUploads) => {
			const upload =
				relation.versions !== undefined && relation.versions.length > 0
					? relation.versions[0]
					: relation.original
			let orderValue: string
			if (selectedFilterBy.sortBy === 'uploadFileName') {
				orderValue = upload.displayName
			} else {
				orderValue = upload.createdBy
			}
			return orderValue.toLowerCase().charAt(0) === letter.toLowerCase()
		})
		orderList(selectedFilterBy['sortBy'], selectedFilterBy, newList)
	}

	const getItemsToFilter = () => {
		const items: Array<string> = sortUploadsList.map((relation: RelationUploads) => {
			const upload =
				relation.versions !== undefined && relation.versions.length > 0
					? relation.versions[0]
					: relation.original
			return selectedFilterBy.sortBy === 'uploadFileName' ? upload.displayName : upload.createdBy
		})
		return items
	}

	useEffect(() => {
		const handleWindowResize = () => {
			setWindowWidth(window.innerWidth)
		}

		window.addEventListener('resize', handleWindowResize)

		return () => {
			window.removeEventListener('resize', handleWindowResize)
		}
	}, [])

	useEffect(() => {
		setSortUploadsList(uploadsList)
	}, [uploadsList])

	return (
		<div className={styles.filtersUploadsModal}>
			<div className={styles.topModal}>
				<div className={styles.headerUploadsModal}>
					<div data-testid="uploads-modal-source" className={styles.textTitle}>
						{totalUploads + ' ' + t('Uploads') + ' ' + sourceName}
					</div>
				</div>
				<div className={styles.containerPaginationAndFilters}>
					<Dropdown
						id={testId + '-dropdown'}
						label={''}
						onChangeFunction={(value: UploadFilter['sortBy']) => {
							setCurrentPage(0)
							setCurrentLetter('')
							handleSortUpload(value)
						}}
						hint={''}
						options={[
							{
								label: t('sort.label.createdDate'),
								value: 'recentlyAdded'
							},
							{
								label: t('sort.label.uploadFileName'),
								value: 'uploadFileName'
							},
							{
								label: t('sort.label.uploadedBy'),
								value: 'uploadedBy'
							},
							{
								label: t('sort.label.submittedHiToLo'),
								value: 'submittedHiToLo'
							},
							{
								label: t('sort.label.submittedLoToHi'),
								value: 'submittedLoToHi'
							},
							{
								label: t('sort.label.matchedHiToLo'),
								value: 'matchedHiToLo'
							},
							{
								label: t('sort.label.matchedLoToHi'),
								value: 'matchedLoToHi'
							}
						]}
						selected="recentlyAdded"
						testId={testId + '-dropdown'}
					/>
					<div className={styles.containerPaginationLabel}>
						<div data-testid="show-pag-label" className={styles.paginationOptionsLabel}>
							{t('pagination.uploads.label')}
						</div>
						<a
							data-testid="20-per-page"
							className={`${styles.uploadsForPages} ${
								uploadsPerPage === 20 ? styles.currentPagination : ''
							}`}
							onClick={() => {
								if (uploadsPerPage !== 20) {
									setUploadsPerPage(20)
									setCurrentPage(0)
								}
							}}
						>
							20
						</a>
						<span className={`${styles.uploadsForPages} ${styles.separator}`}>|</span>
						<a
							data-testid="50-per-page"
							className={`${styles.uploadsForPages} ${
								uploadsPerPage === 50 ? styles.currentPagination : ''
							}`}
							onClick={() => {
								if (uploadsPerPage !== 50) {
									setUploadsPerPage(50)
									setCurrentPage(0)
								}
							}}
						>
							50
						</a>
						<span className={`${styles.uploadsForPages} ${styles.separator}`}>|</span>
						<a
							data-testid="show-all-uploads"
							className={`${styles.uploadsForPages} ${
								uploadsPerPage === 100 ? styles.currentPagination : ''
							}`}
							onClick={() => {
								if (uploadsPerPage !== 100) {
									setUploadsPerPage(100)
									setCurrentPage(0)
								}
							}}
						>
							{t('text.show.all')}
						</a>
						<div data-testid="uploads-label" className={styles.paginationOptionsLabel}>
							{t('uploads')}
						</div>
					</div>
				</div>
				<div className={styles.containerUploads}>
					{t('show.range.uploads', {
						initRange: uploadsPerPage * currentPage + 1,
						endRange: uploadsPerPage * (currentPage + 1)
					})}
				</div>
				{selectedFilterBy.sortBy === 'uploadedBy' || selectedFilterBy.sortBy === 'uploadFileName' ? (
					<AlphabeticalFilter
						onSelection={filterByLetter}
						itemsToFilter={getItemsToFilter()}
						testId={testId + '-uploads-modal'}
						selectedLetter={currentLetter}
					/>
				) : null}
			</div>
			{getContainer()}
			<div className={styles.containerButtons}>
				<Button
					onClick={() => setCurrentPage(currentPage - 1)}
					text={t('text.previous')}
					size={'small'}
					short={true}
					isDisabled={currentPage === 0}
					testId={testId + '-btn-modal-prev'}
				/>
				<Button
					onClick={() => setCurrentPage(currentPage + 1)}
					text={t('text.next', { range: uploadsPerPage })}
					size={'small'}
					short={true}
					isDisabled={currentPage >= Math.ceil(sortUploadsList.length / uploadsPerPage) - 1}
					testId={testId + '-btn-modal-next'}
				/>
			</div>
		</div>
	)
}
